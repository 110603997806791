import { i18n } from "@/config"
import { currentTimestamp } from "@/config/store/StoreItemsModule/helpers"

export const defaultSpecialPeriodObject = date => ({
  id: currentTimestamp(),
  _addedAt: `t_${currentTimestamp()}`,
  fees: {
    fee_1: null,
    fee_2: null,
    fee_3: null,
    fee_4: null,
    fee_5: null,
    fee_6: null,
    fee_7: null,
    fee_8: null,
    fee_9: null,
    fee_10: null,
    over_fee_day: null
  },
  name: "",
  color: 0,
  laneColor: "red",
  start_date: new Date(date),
  end_date: new Date(date),
  period_type: "special"
})

export const SPECIAL_PERIOD_DEFAULT_COLOR_KEY = 1
export const SALE_SUSPENSION_COLOR_KEY = "-1"
export const LANE_COLORS = {
  [SALE_SUSPENSION_COLOR_KEY]: "black",
  0: "orange",
  [SPECIAL_PERIOD_DEFAULT_COLOR_KEY]: "red",
  2: "blue",
  3: "yellow",
  4: "pink",
  5: "green"
}
export const DAYS_OF_WEEK = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]

export const TIME_SLOT_LIST = [
  {
    id: "hourly",
    name: i18n.t("plan_management.time_slot_type.hourly"),
    slots: ["fee_1", "fee_2", "fee_3", "fee_4", "excess_fee"]
  },
  {
    id: "daily",
    name: i18n.t("plan_management.time_slot_type.daily"),
    slots: ["fee_1", "fee_2", "fee_3", "fee_4", "excess_fee"]
  }
]

export const TIME_SLOT_TYPES = [
  {
    id: "all",
    name: i18n.t("plan_management.time_slot_type.all")
  },
  {
    id: "daily",
    name: i18n.t("plan_management.time_slot_type.daily")
  },
  {
    id: "hourly",
    name: i18n.t("plan_management.time_slot_type.hourly")
  }
]

export const PERIOD_TYPES = [
  {
    id: "sale",
    name: i18n.t("plan_management.status.sale")
  },
  {
    id: "posting",
    name: i18n.t("plan_management.status.posting")
  }
]

export const PERIOD_INCLUSION_TYPES = [
  {
    id: "all_included",
    name: i18n.t("plan_management.period_inclusion_type.all_included")
  },
  {
    id: "partially_included",
    name: i18n.t("plan_management.period_inclusion_type.partially_included")
  },
  {
    id: "partially_excluded",
    name: i18n.t("plan_management.period_inclusion_type.partially_excluded")
  },
  {
    id: "all_excluded",
    name: i18n.t("plan_management.period_inclusion_type.all_excluded")
  }
]

export const INSURANCES = [
  {
    id: "all",
    name: i18n.t("plan_management.insurance.all")
  },
  {
    id: "included",
    name: i18n.t("plan_management.insurance.included")
  },
  {
    id: "optional",
    name: i18n.t("plan_management.insurance.optional")
  }
]

export const SALE_STATUSES = [
  {
    id: "all",
    name: i18n.t("plan_management.selling_status.all")
  },
  {
    id: "selling",
    name: i18n.t("plan_management.selling_status.selling")
  },
  {
    id: "stop_selling",
    name: i18n.t("plan_management.selling_status.stop_selling")
  }
]
