<template lang="pug">
  .top-bar.filters
    .row
      .search
        AppSearch(
          :value="filters.searchValue"
          @update="$emit('apply-search', $event)"
        )
    .row.align-items-center
      .filter.col
        p.filter-title {{ $t("plan_management.filters.ota_name") }}
        AppDropdown.ota-name(
          checkbox
          batch-select
          multiple
          close-on-select
          allow-empty
          :value="filters.otas"
          :items="otas"
          @select="$emit('select-otas', $event)"
        )
      .filter.col
        p.filter-title {{ $t("plan_management.filters.shop") }}
        AppDropdown.shop(
          allow-empty
          close-on-select
          :disabled="isEmpty(filters.otas)"
          :value="filters.shop"
          :items="shops"
          :loading="shopsLoading"
          @select="$emit('select-shop', $event)"
        )
      .filter.col
        p.filter-title {{ $t("plan_management.filters.car_classes") }}
        AppDropdown.car-class(
          checkbox
          batch-select
          multiple
          :disabled="isEmpty(filters.shop)"
          :value="filters.carClasses"
          :items="carClasses"
          :loading="carClassesLoading"
          @select="$emit('select-car-classes', $event)"
        )
      .filter.col
        .actions.top-offset
          AppIconButton.more-filters-button(
            title="plan_management.filters.more_filters"
            icon="tune"
            :class="{ pressed: moreFiltersShown }"
            :use-app-icon="true"
            @click="handleMoreFiltersClick"
          )
          AppIconButton.clear(
            title="plan_management.filters.clear"
            icon="trashXMark"
            :use-app-icon="true"
            @click="$emit('clear-filters')"
          )
    Transition(
      name="fade-dropdown"
      mode="out-in"
    )
      .row.align-items-center.more-filters(v-if="moreFiltersShown")
        .filter.col.period-type
          p.filter-title {{ $t("plan_management.filters.date") }}
          AppDropdown.period-type(
            allow-empty
            close-on-select
            :value="filters.period_type"
            :items="periodTypes"
            @select="applyFilter('period_type', $event)"
          )
        .filter.col
          AppDatepicker.date.top-offset(
            :value="filters.dateRange"
            range
            @change="applyFilter('dateRange', $event)"
          )
        .filter.col
          AppDropdown.period-inclusions.top-offset(
            allow-empty
            close-on-select
            :value="filters.periodInclusion"
            :items="periodInclusions"
            @select="applyFilter('periodInclusion', $event)"
          )
        .filter.col
          p.filter-title {{ $t("plan_management.filters.insurance") }}
          AppDropdown.insurance(
            allow-empty
            close-on-select
            :value="filters.insurance"
            :items="insurances"
            @select="applyFilter('insurance', $event)"
          )
        .filter.col
          p.filter-title {{ $t("plan_management.filters.time_slot") }}
          AppDropdown.time_slot(
            allow-empty
            close-on-select
            :value="filters.timeSlotType"
            :items="timeSlotTypes"
            @select="applyFilter('timeSlotType', $event)"
          )
        .filter.col
          p.filter-title {{ $t("plan_management.filters.status") }}
          AppDropdown.sale-status(
            allow-empty
            close-on-select
            :value="filters.saleStatus"
            :items="saleStatuses"
            @select="applyFilter('saleStatus', $event)"
          )
</template>

<script>
  // misc
  import { isEmpty } from "lodash-es"

  import { PERIOD_TYPES, PERIOD_INCLUSION_TYPES, INSURANCES, TIME_SLOT_TYPES, SALE_STATUSES } from "./../constants.js"

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSearch: () => import("@/components/elements/AppSearch"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppButton: () => import("@/components/elements/AppButton")
    },

    props: {
      filters: {
        type: Object,
        default: () => new Object()
      },
      otas: {
        type: Array,
        default: () => new Array()
      },
      shops: {
        type: Array,
        default: () => new Array()
      },
      shopsLoading: {
        type: Boolean,
        default: false
      },
      carClasses: {
        type: Array,
        default: () => new Array()
      },
      carClassesLoading: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        periodTypes: PERIOD_TYPES,
        periodInclusions: PERIOD_INCLUSION_TYPES,
        insurances: INSURANCES,
        timeSlotTypes: TIME_SLOT_TYPES,
        saleStatuses: SALE_STATUSES,
        moreFiltersShown: false
      }
    },

    methods: {
      isEmpty,

      handleMoreFiltersClick() {
        this.moreFiltersShown = !this.moreFiltersShown
      },

      applyFilter(itemType, items) {
        const filters = { ...this.filters, [itemType]: items }
        this.$emit("change-filters", filters)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .top-bar.filters
    +filter-title

    .top-offset
      margin-top: 25px

    .search
      margin-left: 20px
      margin-bottom: 30px

      .app-search
        width: 550px

    .actions
      width: 210px
      display: flex
      justify-content: space-between

      ::v-deep
        .app-button-content
          .title
            margin-left: 5px

      .more-filters-button
        border-color: $default-purple
        color: $default-purple

      .clear
        border-color: $default-purple
        color: $default-purple

      .pressed
        background-color: $default-purple

        ::v-deep
          svg
            path
              fill: $default-white

          .app-button-content
            .title
              color: $default-white

    .more-filters
      margin: 30px 10px 0
      background-color: $default-gray-light
      padding: 15px 5px 25px
      border-radius: 5px

      ::v-deep
        .app-select
          background-color: $default-gray-light

      .period-type
        width: 140px
</style>
